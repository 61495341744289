<template>
	<div class="base-layout">
		<Header />

		<main>
			<slot />
		</main>
	</div>
</template>

<script>
import Header from "@/layouts/Header";
export default {
	name: "BaseLayout",
	components: { Header }
};
</script>

<style lang="scss">
@import "../assets/scss/common";
</style>

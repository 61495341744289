import Vuex from "vuex";
import Vue from "vue";
import TicketService from "@/services/TicketService";

Vue.use(Vuex);

export const store = new Vuex.Store({
	state: {
		tickets: []
	},
	actions: {
		async loadTickets({commit}) {
			const tickets = await TicketService.getAll();
			commit('setTickets', tickets);
		},
		async markAsVisited({commit}, ticketId) {
			await TicketService.markAsVisited(ticketId);
			commit('markAsVisitedLocal', ticketId);
		}
	},
	mutations: {
		setTickets(state, tickets) {
			state.tickets = tickets;
		},
		addTicket(state, ticket) {
			state.tickets.push(ticket);
		},
		markAsVisitedLocal(state, ticketId) {
			const ticket = state.tickets.find(t => t.id === ticketId);
			if (ticket) {
				ticket.visited = true;
			}
		}
	}
});

import httpClient from "@/httpClient";
import FileService from "@/services/FileService";

export default class TicketService {
	static API_ENDPOINT = "ticket";

	static formatTicket(ticketData) {
		const formatTicket = {
			...ticketData.ticket,
			creationDate: new Date(ticketData.ticket.creationDate),
			archivationDate: new Date(ticketData.ticket.archivationDate),
			substation: ticketData.substation
		};

		if (ticketData.comments) {
			formatTicket.comments = ticketData.comments.map(comment => ({
				...comment,
				creationDate: new Date(comment.creationDate)
			}));
		}

		return formatTicket;
	}

	static async getAll() {
		const response = await httpClient().get(`${this.API_ENDPOINT}/findAllTicketSubstation`);

		return response.data.map(this.formatTicket);
	}

	static async getById(id) {
		const response = await httpClient().get(
			`${this.API_ENDPOINT}/findTicketSubsationCommnet?ticketId=${id}`
		);

		return this.formatTicket(response.data);
	}

	static async saveTicket(substationId, cfgFile, datFile) {
		const createdFile = await FileService.uploadComtrade(substationId, cfgFile, datFile);

		return this.getById(createdFile.ticketId);
	}

	static async archiveTicket(id, problems, comment) {
		await httpClient().put(
			`${this.API_ENDPOINT}/setArchiveWithProblemAndComment?ticketID=${id}&problems=${problems}&dispetcher_comment=${comment}`
		);
	}

	static async markAsVisited(id) {
		await httpClient().put(`${this.API_ENDPOINT}/setVisited?ticketId=${id}`);
	}
}

<template>
	<v-dialog :value="isShowing" width="500" persistent>
		<v-card>
			<v-card-title class="headline grey lighten-2">Создать заявку</v-card-title>

			<v-card-text>
				<v-autocomplete
					label="Подстанция"
					:items="stations"
					:item-text="s => s.name"
					:item-value="s => s.id"
					clearable
					:disabled="isFormSubmitting"
					v-model="substationId"
				/>

				<v-file-input
					label="Cfg"
					v-model="cfgFile"
					accept=".cfg"
					:disabled="isFormSubmitting"
				/>

				<v-file-input
					label="Dat"
					v-model="datFile"
					accept=".dat"
					:disabled="isFormSubmitting"
				/>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click="clearAndCloseModal">Закрыть</v-btn>
				<v-btn
					color="primary"
					:disabled="isUploadButtonDisabled"
					:loading="isFormSubmitting"
					text
					@click="saveTicket"
				>
					Загрузить
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import SubstationService from "@/services/SubstationService";
import TicketService from "@/services/TicketService";
import { mapMutations } from "vuex";

export default {
	name: "CreateTicketModalComponent",
	data: () => ({
		isShowing: false,
		cfgFile: null,
		datFile: null,
		stations: [],
		substationId: null,
		isFormSubmitting: false
	}),
	async mounted() {
		this.stations = await SubstationService.getAll();
	},
	methods: {
		...mapMutations(['addTicket']),
		show() {
			this.isShowing = true;
		},
		clearAndCloseModal() {
			this.substationId = this.cfgFile = this.datFile = null;
			this.isShowing = false;
		},
		async saveTicket() {
			this.isFormSubmitting = true;
			const createdTicket = await TicketService.saveTicket(this.substationId, this.cfgFile, this.datFile);
			this.isFormSubmitting = false;

			this.addTicket(createdTicket);

			this.clearAndCloseModal();
		}
	},
	computed: {
		isUploadButtonDisabled() {
			return !this.cfgFile || !this.datFile || !this.substationId;
		}
	},
};
</script>

<style scoped lang="scss">
.v-dialog > .v-card > .v-card__text {
	padding: 24px 20px !important;
}
</style>

<template>
	<v-app>
		<BaseLayout>
			<router-view />
		</BaseLayout>
	</v-app>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout";
export default {
	components: { BaseLayout }
};
</script>

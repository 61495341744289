import httpClient from "@/httpClient";

export default class FileService {
	static API_ENDPOINT = "file";

	static async uploadComtrade(substationId, cfgFile, datFile) {
		const formData = new FormData();
		formData.append("scfFile", cfgFile);
		formData.append("datFile", datFile);

		const response = await httpClient().post(
			`${this.API_ENDPOINT}/comtrade/upload?substantionID=${substationId}`,
			formData
		);

		return response.data;
	}
}

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Ticket List",
		component: () => import("@/pages/TicketListPage.vue")
	},
	{
		path: "/ticket/:id",
		name: "Ticket Page",
		component: () => import("@/pages/TicketPage.vue")
	},
	{
		path: "/online",
		name: "Online sensors",
		component: () => import("@/pages/OnlineSensorPage.vue")
	},
	{
		path: "/substations",
		name: "Substation List",
		component: () => import("@/pages/SubstationListPage.vue")
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

export default router;
